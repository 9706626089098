import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription, combineLatest } from "rxjs";
import { Collection, CollectionDetails, Point } from "src/app/models/dashboard";
import { BackendService } from "src/app/services/backend/backend.service";
import { SiteFilterService } from "src/app/services/site-filter.service";
import { StoreService } from "src/app/services/store.service";
import { RSocket, RSocketClient } from "rsocket-core";
import { BINARY, Operation, ViewMode } from "src/app/config/constants";
import { UtilsService } from "src/app/services/utils/util.service";
import { OAuthService } from 'angular-oauth2-oidc';

const SUMMARY = "summary";
const DETAILED = "detailed";
@Component({
    selector: "cc-collection-pinned-view",
    templateUrl: "./pinned-view.component.html",
    styleUrls: ["./pinned-view.component.scss"],
})
export class CollectionPinnedViewComponent implements OnInit {
    collectionDetailsList: CollectionDetails[];
    collections: Collection[];
    collectionPointsArray: Point[] = [];

    pointsObj: { [key: string]: { [key: string]: Point[] } } = {};
    category = "collection";
    isOverrideResetOn: boolean = false;
    userHasWritePermission$: Observable<boolean>;
    view: string = ViewMode.PINNED;
    cardType: string = SUMMARY;
    username: string = "";
    noOfPinnedCollections: number = null;
    selectedSite: { siteId: string; customerId: string };

    private mainSubscription: Subscription;
    private polledPointsValuesSubscription: Subscription;
    private rSocketClient: RSocketClient;

    constructor(
        private backendService: BackendService,
        private activatedRoute: ActivatedRoute,
        private siteFilterService: SiteFilterService,
        private storeService: StoreService,
        private utilsService: UtilsService,
        private authService: OAuthService,
    ) {}

    ngOnInit(): void {
        this.userHasWritePermission$ = this.storeService.observable;

        this.storeService.canRemoveOverrideChanged$.subscribe((value) => {
            this.isOverrideResetOn = value;
        });

        this.username = this.authService.getIdentityClaims()['email'];

        this.mainSubscription = combineLatest([
            this.activatedRoute.paramMap,
            this.siteFilterService.siteFilterChanged$,
        ]).subscribe(([routeParamMap, selectedSite]) => {
            this.selectedSite = selectedSite;
            let siteId = selectedSite?.siteId;

            if (!siteId) {
                return;
            }

            this.getPinnedCollections();
        });
    }

    ngOnDestroy() {
        if (this.mainSubscription) {
            this.mainSubscription.unsubscribe();
        }

        if (this.polledPointsValuesSubscription) {
            this.polledPointsValuesSubscription.unsubscribe();
        }

        this.closeRsocket();
    }

    callKpiPolling(siteId: string, datapointIdListFilter: string[]) {
        ////////
        // Create an instance of a rSocketClient
        this.rSocketClient = this.backendService.getRSocketClient();
        // Open the connection
        this.polledPointsValuesSubscription = this.rSocketClient
            .connect()
            .subscribe({
                onComplete: (socket: RSocket) => {
                    let requestStream = "request-stream-get-datapoint-list2";
                    socket
                        .requestStream({
                            data: {
                                siteId: siteId,
                                datapointIdListFilter: datapointIdListFilter,
                                //TODO get token from authentication
                                jwtToken: this.authService.getIdToken(),
                                interaction: "Request",
                            }, // null is a must if it does not include a message payload, else the Spring server side will not be matched.
                            metadata:
                                String.fromCharCode(requestStream.length) +
                                requestStream,
                        })
                        .subscribe({
                            onComplete: () => console.log("complete"),
                            onError: (error) => {
                                console.log(
                                    "Connection has been closed due to:: " +
                                        error
                                );
                            },
                            onNext: (payload) => {
                                if (this.cardType === SUMMARY) {
                                    this.mapCollectionKPI(payload.data);
                                }
                                if (this.cardType === DETAILED) {
                                    this.mapCollectionPoints(payload.data);
                                }
                            },
                            onSubscribe: (subscription) => {
                                subscription.request(1000000);
                            },
                        });
                },
                onError: (error) => {
                    console.log(
                        "Connection has been refused due to:: " + error
                    );
                },
                onSubscribe: (cancel) => {
                    /* call cancel() to abort */
                },
            });
    }

    mapCollectionPoints(point: any) {
        //do not update locked values;

        if (
            Object.keys(this.storeService.getLockedPoints()).includes(point.id)
        ) {
            return;
        }

        let pointToUpdate = this.collectionPointsArray.find(
            (collectionPoint) => collectionPoint.pointUUID === point.id
        );

        if (pointToUpdate) {
            pointToUpdate.extensions = point.extensions;
            pointToUpdate.presentValue = point.lastRecordedValue;
            pointToUpdate.signalType = point.signalType;
            pointToUpdate.type = point.type;
            pointToUpdate.units = point.units;
            pointToUpdate.isWritable = this.isPointWritable(pointToUpdate);
            pointToUpdate.isOverridden = point.isOverridden;
            pointToUpdate.activeOverrides = point.activeOverrides;

            if (point.signalType === BINARY) {
                pointToUpdate.presentValue = point.lastRecordedValue
                    ? true
                    : false;
            }
        }
    }

    mapCollectionKPI(point: any) {
        const mappedPointsPerId = {};

        this.collections.forEach((collection) => {
            const pointObjectReference = collection.selectedKpi;
            if (pointObjectReference) {
                mappedPointsPerId[pointObjectReference.pointUUID] =
                    pointObjectReference;
            }
        });

        const existingPointObjectReference = mappedPointsPerId[point.id];
        if (existingPointObjectReference) {
            existingPointObjectReference.presentValue = point.lastRecordedValue;
            existingPointObjectReference.signalType = point.signalType;
            existingPointObjectReference.type = point.type;
            existingPointObjectReference.units = point.units;

            if (point.signalType === BINARY) {
                existingPointObjectReference.presentValue =
                    point.lastRecordedValue ? true : false;
            }
        }
    }

    showSummary() {
        this.closeRsocket();
        this.collectionPointsArray = [];

        if (this.collections?.length) {
            this.collections.forEach((collection) => {
                this.collectionPointsArray.push(collection.selectedKpi);
            });

            this.callKpiPolling(
                this.selectedSite.siteId,
                this.collections
                    .flatMap((value) => value.selectedKpi)
                    .map((value: any) => value.pointUUID)
            );
        } else {
            this.getPinnedCollections();
        }
        this.cardType = SUMMARY;
    }

    showDetailed() {
        this.closeRsocket();
        this.collectionPointsArray = [];

        if (this.collectionDetailsList?.length) {
            this.collectionDetailsList.forEach((collectionDetails) => {
                this.collectionPointsArray.push(
                    ...collectionDetails.setpointsAndParametersDatapointsList,
                    ...collectionDetails.commandsDatapointsList,
                    ...collectionDetails.sensorsAndStatusDatapointsList,
                    ...collectionDetails.alarmsDatapointsList
                );
            });

            this.callKpiPolling(
                this.selectedSite.siteId,
                this.collectionPointsArray.map((point) => point.pointUUID)
            );
        } else {
			this.getPinnedCollectionsDetails();
        }
        this.cardType = DETAILED;
    }

    toggleCardState(event: any) {
        if (event.target.checked) {
            this.showSummary();
        }
        if (!event.target.checked) {
            this.showDetailed();
        }
    }

    togglePin(event: MouseEvent, collection: Collection) {
        event.stopPropagation();
        const operation = collection.pinned ? Operation.Delete : Operation.Add;
        this.backendService
            .entityAddRemovePin(
                this.category,
                this.username,
                this.selectedSite,
                operation,
                `urn:uuid:${collection.id}`
            )
            .subscribe(
                () => {
                    collection.pinned = !collection.pinned;
                    if (collection.pinned) {
                        this.noOfPinnedCollections++;
                    } else {
                        this.noOfPinnedCollections--;
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
    }

	private getPinnedCollections() {
		this.backendService
                .getPinnedCollections(this.username, this.selectedSite)
                .subscribe((response: any[]) => {
                    this.collections = response;
                    this.noOfPinnedCollections = this.collections.length;

                    this.collections.forEach((collection) => {
                        this.collectionPointsArray.push(collection.selectedKpi);
                    });

                    this.callKpiPolling(
                        this.selectedSite.siteId,
                        response
                            .flatMap((value) => value.selectedKpi)
                            .map((value: any) => value.pointUUID)
                    );
                });
	}

	private getPinnedCollectionsDetails() {
		const pinnedCollectionIds = this.collections.map(
			(collection) => collection.id
		);

		this.backendService
                .getPinnedCollectionsDetailes(
                    this.username,
                    this.selectedSite,
                    pinnedCollectionIds
                )
                .subscribe((response: CollectionDetails[]) => {
                    this.collectionDetailsList = response;
                    this.collectionDetailsList.forEach((collectionDetails) => {
						const pointsArray = [...collectionDetails.setpointsAndParametersDatapointsList,
                            ...collectionDetails.commandsDatapointsList,
                            ...collectionDetails.sensorsAndStatusDatapointsList,
							...collectionDetails.alarmsDatapointsList];
                        this.collectionPointsArray.push(
                            ...pointsArray
                        );

						this.pointsObj[collectionDetails.label] = {[collectionDetails.label]: pointsArray};
                    });

                    this.callKpiPolling(
                        this.selectedSite.siteId,
                        this.collectionPointsArray.map(
                            (point) => point.pointUUID
                        )
                    );
                });
	}

    private isPointWritable(point: Point) {
        const pointMainClass = point.class.split("_").pop();
        if (
            ["Command", "Parameter", "Setpoint", "Limit"].includes(
                pointMainClass
            )
        )
            return true;
        if (["Alarm", "Sensor", "Status"].includes(pointMainClass))
            return false;
    }

    private closeRsocket() {
        if (this.rSocketClient) {
            this.rSocketClient.close();
        }
    }
}
