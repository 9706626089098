import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, combineLatest } from "rxjs";
import { EquipmentDetails, EquipmentV2, Point } from "src/app/models/dashboard";
import { BackendService } from "src/app/services/backend/backend.service";
import { SiteFilterService } from "src/app/services/site-filter.service";
import { StoreService } from "src/app/services/store.service";
import { RSocket, RSocketClient } from "rsocket-core";
import { BINARY, Operation, ViewMode } from "src/app/config/constants";
import { OAuthService } from 'angular-oauth2-oidc';

const SUMMARY = "summary";
const DETAILED = "detailed";
@Component({
    selector: "cc-equipment-pinned-view",
    templateUrl: "./pinned-view.component.html",
    styleUrls: ["./pinned-view.component.scss"],
})
export class EquipmentPinnedViewComponent implements OnInit {
    equipmentDetailsList: EquipmentDetails[];
    equipments: EquipmentV2[];
    equipmentPointsArray: Point[] = [];

    pointsObj: { [key: string]: { [key: string]: Point[] } } = {};
    category = "equipment";
    isOverrideResetOn: boolean = false;
    userHasWritePermission$: Observable<boolean>;
    view: string = ViewMode.PINNED;
    cardType: string = SUMMARY;
    username: string = "";
    noOfPinnedEquipments: number = null;
    selectedSite: { siteId: string; customerId: string };

    private mainSubscription: Subscription;
    private polledPointsValuesSubscription: Subscription;
    private rSocketClient: RSocketClient;

    constructor(
        private backendService: BackendService,
        private activatedRoute: ActivatedRoute,
        private siteFilterService: SiteFilterService,
        private storeService: StoreService,
        private authService: OAuthService,
    ) {}

    ngOnInit(): void {
        this.userHasWritePermission$ = this.storeService.observable;

        this.storeService.canRemoveOverrideChanged$.subscribe((value) => {
            this.isOverrideResetOn = value;
        });

        this.username = this.authService.getIdentityClaims()['email'];

        this.mainSubscription = combineLatest([
            this.activatedRoute.paramMap,
            this.siteFilterService.siteFilterChanged$,
        ]).subscribe(([routeParamMap, selectedSite]) => {
            this.selectedSite = selectedSite;
            let siteId = selectedSite?.siteId;

            if (!siteId) {
                return;
            }

            this.getPinnedEquipments();
        });
    }

    ngOnDestroy() {
        if (this.mainSubscription) {
            this.mainSubscription.unsubscribe();
        }

        if (this.polledPointsValuesSubscription) {
            this.polledPointsValuesSubscription.unsubscribe();
        }

        this.closeRsocket();
    }

    callKpiPolling(siteId: string, datapointIdListFilter: string[]) {
        ////////
        // Create an instance of a rSocketClient
        this.rSocketClient = this.backendService.getRSocketClient();
        // Open the connection
        this.polledPointsValuesSubscription = this.rSocketClient
            .connect()
            .subscribe({
                onComplete: (socket: RSocket) => {
                    let requestStream = "request-stream-get-datapoint-list2";
                    socket
                        .requestStream({
                            data: {
                                siteId: siteId,
                                datapointIdListFilter: datapointIdListFilter,
                                //TODO get token from authentication
                                'jwtToken': this.authService.getIdToken(),
                                interaction: "Request",
                            }, // null is a must if it does not include a message payload, else the Spring server side will not be matched.
                            metadata:
                                String.fromCharCode(requestStream.length) +
                                requestStream,
                        })
                        .subscribe({
                            onComplete: () => console.log("complete"),
                            onError: (error) => {
                                console.log(
                                    "Connection has been closed due to:: " +
                                        error
                                );
                            },
                            onNext: (payload) => {
                                if (this.cardType === SUMMARY) {
                                    this.mapEquipmentKPI(payload.data);
                                }
                                if (this.cardType === DETAILED) {
                                    this.mapEquipmentPoints(payload.data);
                                }
                            },
                            onSubscribe: (subscription) => {
                                subscription.request(1000000);
                            },
                        });
                },
                onError: (error) => {
                    console.log(
                        "Connection has been refused due to:: " + error
                    );
                },
                onSubscribe: (cancel) => {
                    /* call cancel() to abort */
                },
            });
    }

    mapEquipmentPoints(point: any) {
        //do not update locked values;

        if (
            Object.keys(this.storeService.getLockedPoints()).includes(point.id)
        ) {
            return;
        }

        let pointToUpdate = this.equipmentPointsArray.find(
            (equipmentPoint) => equipmentPoint.pointUUID === point.id
        );

        if (pointToUpdate) {
            pointToUpdate.extensions = point.extensions;
            pointToUpdate.presentValue = point.lastRecordedValue;
            pointToUpdate.signalType = point.signalType;
            pointToUpdate.type = point.type;
            pointToUpdate.units = point.units;
            pointToUpdate.isWritable = this.isPointWritable(pointToUpdate);
            pointToUpdate.isOverridden = point.isOverridden;
            pointToUpdate.activeOverrides = point.activeOverrides;

            if (point.signalType === BINARY) {
                pointToUpdate.presentValue = point.lastRecordedValue
                    ? true
                    : false;
            }
        }
    }

    mapEquipmentKPI(point: any) {
        const mappedPointsPerId = {};

        this.equipments.forEach((equipment) => {
            const pointObjectReference = equipment.selectedKpi;
            if (pointObjectReference) {
                mappedPointsPerId[pointObjectReference.pointUUID] =
                    pointObjectReference;
            }
        });

        const existingPointObjectReference = mappedPointsPerId[point.id];
        if (existingPointObjectReference) {
            existingPointObjectReference.presentValue = point.lastRecordedValue;
            existingPointObjectReference.signalType = point.signalType;
            existingPointObjectReference.type = point.type;
            existingPointObjectReference.units = point.units;

            if (point.signalType === BINARY) {
                existingPointObjectReference.presentValue =
                    point.lastRecordedValue ? true : false;
            }
        }
    }

    showSummary() {
        this.closeRsocket();
        this.equipmentPointsArray = [];

        if (this.equipments?.length) {
            this.equipments.forEach((equipment) => {
                this.equipmentPointsArray.push(equipment.selectedKpi);
            });

            this.callKpiPolling(
                this.selectedSite.siteId,
                this.equipments
                    .flatMap((value) => value.selectedKpi)
                    .map((value: any) => value.pointUUID)
            );
        } else {
            this.getPinnedEquipments();
        }
        this.cardType = SUMMARY;
    }

    showDetailed() {
        this.closeRsocket();
        this.equipmentPointsArray = [];

        if (this.equipmentDetailsList?.length) {
            this.equipmentDetailsList.forEach((equipmentDetails) => {
                this.equipmentPointsArray.push(
                    ...equipmentDetails.setpointsAndParametersDatapointsList,
                    ...equipmentDetails.commandsDatapointsList,
                    ...equipmentDetails.sensorsAndStatusDatapointsList,
                    ...equipmentDetails.alarmsDatapointsList
                );
            });

            this.callKpiPolling(
                this.selectedSite.siteId,
                this.equipmentPointsArray.map((point) => point.pointUUID)
            );
        } else {
			this.getPinnedEquipmentsDetails();
        }
        this.cardType = DETAILED;
    }

    toggleCardState(event: any) {
        if (event.target.checked) {
            this.showSummary();
        }
        if (!event.target.checked) {
            this.showDetailed();
        }
    }

    togglePin(event: MouseEvent, equipment: EquipmentV2) {
        event.stopPropagation();
        const operation = equipment.pinned ? Operation.Delete : Operation.Add;
        this.backendService
            .entityAddRemovePin(
                this.category,
                this.username,
                this.selectedSite,
                operation,
                `urn:uuid:${equipment.id}`
            )
            .subscribe(
                () => {
                    equipment.pinned = !equipment.pinned;
                    if (equipment.pinned) {
                        this.noOfPinnedEquipments++;
                    } else {
                        this.noOfPinnedEquipments--;
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
    }

	private getPinnedEquipments() {
		this.backendService
                .getPinnedEquipments(this.username, this.selectedSite)
                .subscribe((response: any[]) => {
                    this.equipments = response;
                    this.noOfPinnedEquipments = this.equipments.length;

                    this.equipments.forEach((equipment) => {
                        this.equipmentPointsArray.push(equipment.selectedKpi);
                    });

                    this.callKpiPolling(
                        this.selectedSite.siteId,
                        response
                            .flatMap((value) => value.selectedKpi)
                            .map((value: any) => value.pointUUID)
                    );
                });
	}

	private getPinnedEquipmentsDetails() {
		const pinnedEquipmentIds = this.equipments.map(
			(equipment) => equipment.id
		);

		this.backendService
                .getPinnedEquipmentsDetailes(
                    this.username,
                    this.selectedSite,
                    pinnedEquipmentIds
                )
                .subscribe((response: EquipmentDetails[]) => {
                    this.equipmentDetailsList = response;
                    this.equipmentDetailsList.forEach((equipmentDetails) => {
						const pointsArray = [...equipmentDetails.setpointsAndParametersDatapointsList,
                            ...equipmentDetails.commandsDatapointsList,
                            ...equipmentDetails.sensorsAndStatusDatapointsList,
							...equipmentDetails.alarmsDatapointsList];
                        this.equipmentPointsArray.push(
                            ...pointsArray
                        );

						this.pointsObj[equipmentDetails.label] = {[equipmentDetails.label]: pointsArray};
                    });

                    this.callKpiPolling(
                        this.selectedSite.siteId,
                        this.equipmentPointsArray.map(
                            (point) => point.pointUUID
                        )
                    );
                });
	}

    private isPointWritable(point: Point) {
        const pointMainClass = point.class.split("_").pop();
        if (
            ["Command", "Parameter", "Setpoint", "Limit"].includes(
                pointMainClass
            )
        )
            return true;
        if (["Alarm", "Sensor", "Status"].includes(pointMainClass))
            return false;
    }

    private closeRsocket() {
        if (this.rSocketClient) {
            this.rSocketClient.close();
        }
    }
}
