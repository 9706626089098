export enum ENV_NAME {
    PROD = "PROD",
    DEV = "DEV",
    LOCAL = "LOCAL"
}

// export const AAD_B2C = {
//     'DEV': {
//         clientId: '093651c6-fb00-4c22-999e-ab38f8244419',
//         aadAuthority: 'https://eonemsb2c.b2clogin.com/login.dev-qa.eon-optimum.com/B2C_1_connect',
//         resourceScope: 'https://login.dev-qa.eon-optimum.com/candc/connect.control',
//         knownAuthorities: 'eonemsb2c.b2clogin.com'
//     },
//     'PROD': {
//         clientId: '0bb0d8f7-d6b2-4f87-b079-4ce15f030538',
//         aadAuthority: 'https://optimumprodb2c.b2clogin.com/optimumprodb2c.onmicrosoft.com/B2C_1_Connect',
//         resourceScope: 'https://optimumprodb2c.onmicrosoft.com/candc/connect.control',
//         knownAuthorities: 'optimumprodb2c.b2clogin.com'
//     }
// }

export const AUTH0_CONFIG = {
    'DEV': {
        issuer: 'https://login.dev.eon-optimum.com',
        redirectUri: window.location.origin,
        //clientId: 'wedf8bYdPwL2Kz8NNZltYUaUa8OrWVZQ',
        clientId: 'LqyW08BevUCrSvNHs8hwCGwK6hwD9qhU',
        scope: 'openid name email',
        responseType: 'code',
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    },
    'PROD': {
        issuer: 'https://login.eon-optimum.com',
        redirectUri: window.location.origin,
        clientId: 'lOmEO7dGVfVON7ZuFSzHedcENqmsgIMw',
        scope: 'openid name email',
        responseType: 'code',
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    }
}

export enum TimeFramesValues {
    TODAY = 0,
    YESTERDAY = 1,
    LAST_7_DAYS = 7,
    LAST_30_DAYS = 30
}

export enum Operation {
    Add = 'ADD',
    Delete = 'DELETE'
}

export const PAGINATION = {
    SIZE: 100,
    DEFAULT_PAGE_NR: 0
}
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_1000 = 1000;
export const PAGE_SIZE_10k = 10000;

export const ExternalSource = {
    BACKNET: 'CC',
    WISEMETER: 'WiseMeter'
};

export const TRUE = 'true';
export const ANALOG = 'Analog';
export const BINARY = 'Binary';
export const MULTISTATE = 'MultiState';
export const MANUAL_DATAPOINTS_TABLE_SIZE = 'cbms.datapoints.page.size';

export const ViewMode = {
    PINNED: 'pinned',
    EQUIPMENT: 'equipment'
}

export const  JWT_TOKEN_WEBSCKET = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2VvbmVtc2IyYy5iMmNsb2dpbi5jb20vMjYxYWEwYmEtODQzMC00NzVhLWI1ZGMtNjk5ZmVmMjA3Y2Q4L3YyLjAvIiwiZXhwIjoxNjQ2NzQ2MDA2LCJuYmYiOjE2NDY3NDI0MDYsImF1ZCI6IjA5MzY1MWM2LWZiMDAtNGMyMi05OTllLWFiMzhmODI0NDQxOSIsIm9pZCI6ImZlMGI1MjBhLWZhZTYtNDZjNi1hN2NiLTI4Y2JlYjJkZGU0MSIsInN1YiI6ImZlMGI1MjBhLWZhZTYtNDZjNi1hN2NiLTI4Y2JlYjJkZGU0MSIsIm5hbWUiOiJBbGV4YW5kcnUgTHVuZ3UiLCJlbWFpbHMiOlsiYWxleGFuZHJ1Lmx1bmd1QGVvbi1yb21hbmlhLnJvIl0sInRmcCI6IkIyQ18xX2Nvbm5lY3QiLCJub25jZSI6Ijg0YThkYWJiLWE4MjktNDU3NS05MjJjLWE5NzY2MjQxNjhhNiIsInNjcCI6ImNvbm5lY3QuY29udHJvbCIsImF6cCI6IjA5MzY1MWM2LWZiMDAtNGMyMi05OTllLWFiMzhmODI0NDQxOSIsInZlciI6IjEuMCIsImlhdCI6MTY0Njc0MjQwNn0.DSOWkP9mzxezcfB1WauVSTgqckEUKy2UarIR8g7mULjsuHTjp1VgB65oIlmDAHv_3JjIOs8PkdU2nl1cwSB6Kkswk8-jGZIit8yyaIi8NDu7nr7Ok_YWVAy23Idyq2UaIqxdNhjqbg3q09obxxxBgaVpcNcCOhd_2eh7iV-APnljQ7x6SZR3YreIbSLgGNVO0-r6AmxmR4c4s2nyO90zJ12C0soo8iO8T1UsYkNScCq_lnAO3K2ObNYv1T3eNDbKlDU8C_ug0UGvUYWG-hg-7Z60zGTtJFuiBaADRYOmAvkmeWH9M6MofXKlil2tr4jf_poT_Tiu3ybZ4uqy2RNkgw';

export const COLOR_LIST = [
    "#EA1C0A", //eon-red
    "#008000", //eon-green
    "#1EA2B1", //eon-turquoise
    "#B00402", //eon-bordeaux
    "#E3E000", //eon-limeyellow
    "#D51607", //eon-red-active
    "#2B91A3", //eon-turqoise-active
    "#A00311", //eon-bordeaux-active
    "#DAD600", //eon-limeyellow-active
    "#f05548", //eon-red-75
    "#76020C", //eon-bordeaux-dark
    "#0F738A", //eon-turquoise-dark
    "#eae840", //eon-limeyellow-75
    "#d78180", //eon-bordeaux-50,
    "#f48D84", //eon-red-50
    "#56b9c5", //eon-turquoise-75
    "#f1ef7f", //eon-limeyellow-50
];

export const ONE_HOUR_IN_SECONDS = 3600000;

export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    RED: '#EA1C0A',
    TURQOISE: '#1EA2B1',
    LIME_YELLOW: '#E3E000'
};

export const ANY = 'Any';

export enum Months {
    Any = 0,
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}

export enum DayOfWeek {
    Any = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}

export const WEEK_OF_MONTH_TEXT = {
    0: 'Any',
    1: 'Week1',
    2: 'Week2',
    3: 'Week3',
    4: 'Week4',
    5: 'Week5',
    6: 'Last week of month',
    7: '2nd last week of the month',
    8: '3rd last week of the month',
    9: '4th last week of the month'
};
