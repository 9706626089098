import { Component, OnInit } from "@angular/core";
import { BINARY, Operation } from "src/app/config/constants";
import { Location, EntityFilters, Point } from "src/app/models/dashboard";
import { BackendService } from "src/app/services/backend/backend.service";
import {
    EventNotificationService,
    EventType,
} from "src/app/services/notification/event-notification.service";
import { SiteFilterService } from "src/app/services/site-filter.service";
import { StoreService } from "src/app/services/store.service";
import { RSocket, RSocketClient } from "rsocket-core";
import { Subscription } from "rxjs";
import { UtilsService } from "src/app/services/utils/util.service";
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: "cc-locations",
    templateUrl: "./locations.component.html",
    styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit {
    locations: Location[] = [];
    mobileViewSelected = false;

    polledKPIPointsValuesSubscription: Subscription;

    isSearchActive: boolean = false;
    isFilterActive: boolean = false;
    selectedValues: string[] = [];

    locationFiltersOptions: EntityFilters[] = [];
    selectedLocationClassList: string[] = [];
    selectedFilters: string[] = [];

    selectedSite: { siteId: string; customerId: string };
    searchText: string = "";
    username: string = "";
    category = "location";
    firstSiteLoad = true;
    noOfPinnedLocations: number = null;

	private rSocketClient: RSocketClient;

    constructor(
        private backendService: BackendService,
        private authService: OAuthService,
        private siteFilterService: SiteFilterService,
        private utilsService: UtilsService,
        private eventNotificationService: EventNotificationService
    ) {}

    ngOnInit() {
		this.username = this.authService.getIdentityClaims()['email'];

        this.siteFilterService.siteFilterChanged$.subscribe((selectedSite) => {
            if (selectedSite === null) {
                return;
            }
            if (this.firstSiteLoad) {
                this.restoreFilters();
            }
            if (!this.firstSiteLoad) {
                this.resetFilters();
            }

            this.selectedSite = selectedSite;
            this.firstSiteLoad = false;
            this.clearPreviousData();
            this.getLocations();
        });

        this.eventNotificationService
            .getEventDispatcher$()
            .subscribe((event) => {
                if (event.type === EventType.MOBILE_VIEW_SELECTED) {
                    this.mobileViewSelected = <boolean>event.payload;
                }
            });
    }

    restoreFilters() {
        const filters =
            JSON.parse(sessionStorage.getItem(`${this.category}-filters`)) ||
            null;

        if (filters) {
            this.searchText = filters.searchText;
            this.selectedLocationClassList = filters.selectedClassList;
        }
    }

    resetFilters() {
        this.searchText = "";
        this.selectedLocationClassList = [];
        sessionStorage.setItem(
            `${this.category}-filters`,
            JSON.stringify({
                searchText: this.searchText,
                selectedClassList: this.selectedLocationClassList,
            })
        );
    }

    onFilterChangedHandler($event: any) {
        this.searchText = $event.searchText;
        this.selectedLocationClassList = $event.selectedClassList;

        if ($event.action === "apply") {
            this.filterLocations();
        }

        if ($event.action === "clearFilter") {
            this.clearLocationsFilters();
        }

        if ($event.action === "clearSearch") {
            this.clearSearchInput();
        }
    }

    getLocations() {
        this.backendService
            .getDataByCategory(
                this.category,
                this.username,
                this.selectedSite,
                this.searchText,
                this.selectedLocationClassList
            )
            .subscribe((response: Location[]) => {
                this.locations = response;
                this.noOfPinnedLocations = this.locations.filter(item => item.pinned).length;
                this.generateLocationsFilters();
                this.callKpiPolling(response
                    .flatMap((value) => value.selectedKpi)
                    .map((value: any) => value.pointUUID))
            });
    }

    generateLocationsFilters() {
        this.backendService
            .getLocationsClassifications(this.selectedSite.siteId)
            .subscribe((response: any[]) => {
                let locationsClassification = {};

                response.map((item) => {
                    let locationCategory = item.category.split("#")[1];
                    if (!locationsClassification[locationCategory]) {
                        locationsClassification[locationCategory] = [];
                    }
                    locationsClassification[locationCategory].push(
                        item.entityClass.split("#")[1].replaceAll("_", " ")
                    );
                });

                this.locationFiltersOptions = Object.keys(
                    locationsClassification
                ).map((category) => {
                    return {
                        label: category.replaceAll("_", " "),
                        value: category,
                        items: locationsClassification[category].map(
                            (eqClass: string) => {
                                return {
                                    label: eqClass.replaceAll("_", " "),
                                    value: eqClass,
                                };
                            }
                        ),
                    };
                });
            });
    }

    filterLocations() {
        this.backendService
            .getDataByCategory(
                this.category,
                this.username,
                this.selectedSite,
                this.searchText,
                this.selectedLocationClassList
            )
            .subscribe((response: Location[]) => {
                this.locations = response;
            });
    }

    clearFilters(searchText: string, equipmentClassList: string[]) {
        this.backendService
            .getDataByCategory(
                this.category,
                this.username,
                this.selectedSite,
                searchText,
                equipmentClassList
            )
            .subscribe((response: Location[]) => {
                this.locations = response;
            });
    }

    clearSearchInput() {
        this.searchText = "";
        this.clearFilters(this.searchText, this.selectedLocationClassList);
    }

    clearLocationsFilters() {
        this.selectedLocationClassList = [];
        this.clearFilters(this.searchText, this.selectedLocationClassList);
    }

    clearPreviousData() {
        if (this.polledKPIPointsValuesSubscription) {
			this.polledKPIPointsValuesSubscription.unsubscribe();
		}
		this.closeRsocket();
        this.locations = [];
    }

    callKpiPolling(datapointIdListFilter: string[]) {
		////////
		// Create an instance of a rSocketClient
		this.rSocketClient = this.backendService.getRSocketClient();
		// Open the connection
		this.polledKPIPointsValuesSubscription = this.rSocketClient.connect().subscribe({
			onComplete: (socket: RSocket) => {
				let requestStream = 'request-stream-get-datapoint-list2';
				socket
					.requestStream({
						data: {
							'siteId': this.selectedSite.siteId,
							'datapointIdListFilter': datapointIdListFilter.filter(datapointId => datapointId !== null),
							jwtToken: this.authService.getIdToken(),
							'interaction': 'Request'
						}, // null is a must if it does not include a message payload, else the Spring server side will not be matched.
						metadata: String.fromCharCode(requestStream.length) + requestStream
					})
					.subscribe({
						onComplete: () => console.log('complete'),
						onError: error => {
							console.log("Connection has been closed due to:: " + error);
						},
						onNext: payload => {
							this.mapLocationKPI(payload.data);
						},
						onSubscribe: subscription => {
							subscription.request(1000000);
						},
					});

			},
			onError: error => {
				console.log("Connection has been refused due to:: " + error);
			},
			onSubscribe: cancel => {
				/* call cancel() to abort */
			}
		});

	}

    mapLocationKPI(point: any) {
		const mappedPointsPerId = {};

		this.locations.forEach(location => {
			const pointObjectReference = location.selectedKpi;
			if (pointObjectReference) {
				mappedPointsPerId[pointObjectReference.pointUUID] = pointObjectReference;
			}
		})

		const existingPointObjectReference = mappedPointsPerId[point.id];
		if (existingPointObjectReference) {
			existingPointObjectReference.presentValue = point.lastRecordedValue;
			existingPointObjectReference.signalType = point.signalType;
			existingPointObjectReference.type = point.type;
			existingPointObjectReference.units = point.units;

			if (point.signalType === BINARY) {
				existingPointObjectReference.presentValue = point.lastRecordedValue ? true : false;
			}
		}
	}

    togglePin(event: MouseEvent, location: Location) {
		event.stopPropagation();
		const operation = location.pinned ? Operation.Delete : Operation.Add
		this.backendService.entityAddRemovePin(this.category, this.username, this.selectedSite, operation, `urn:uuid:${location.id}`).subscribe((response) => {
			location.pinned = !location.pinned;
			if (location.pinned) {
				this.noOfPinnedLocations++;
			} else {
				this.noOfPinnedLocations--;
			}
		}, (err) => {
			console.log(err);
		})
	}

    ngOnDestroy() {
		if (this.polledKPIPointsValuesSubscription) {
			this.polledKPIPointsValuesSubscription.unsubscribe();
		}

		this.closeRsocket();
	}

    private closeRsocket() {
		if (this.rSocketClient) {
			this.rSocketClient.close();
		}
	}
}
