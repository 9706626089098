import { Component, OnInit } from "@angular/core";
import { BINARY, Operation } from "src/app/config/constants";
import { Collection, EntityFilters, Point } from "src/app/models/dashboard";
import { BackendService } from "src/app/services/backend/backend.service";
import { EventNotificationService, EventType } from "src/app/services/notification/event-notification.service";
import { SiteFilterService } from "src/app/services/site-filter.service";
import { RSocket, RSocketClient } from "rsocket-core";
import { Subscription } from "rxjs";
import { UtilsService } from "src/app/services/utils/util.service";
import { OAuthService } from 'angular-oauth2-oidc';


@Component({
    selector: "cc-collections",
    templateUrl: "./collections.component.html",
    styleUrls: ["./collections.component.scss"],
})
export class CollectionsComponent implements OnInit {
    collections: Collection[] = [];
    mobileViewSelected = false;

	polledKPIPointsValuesSubscription: Subscription;

    isSearchActive: boolean = false;
	isFilterActive: boolean = false;
	selectedValues: string[] = [];

	collectionFiltersOptions: EntityFilters[] = [];
	selectedCollectionClassList: string[] = [];
	selectedFilters: string[] = [];

	selectedSite: { siteId: string, customerId: string };
	searchText: string = '';
	username: string = '';
	category = 'collection';
	firstSiteLoad = true;
	noOfPinnedCollections: number = null;

	private rSocketClient: RSocketClient;

    constructor(private backendService: BackendService,
		private siteFilterService: SiteFilterService,
        private utilsService: UtilsService,
		private eventNotificationService: EventNotificationService,
		private authService: OAuthService) { }

    ngOnInit() {
		const claims = this.authService.getIdentityClaims();
        this.username = claims ? claims['email'] : '';

		this.siteFilterService.siteFilterChanged$.subscribe(selectedSite => {
			if (selectedSite === null) {
				return;
			}
			if (this.firstSiteLoad) {			
				this.restoreFilters();
			} 
			if (!this.firstSiteLoad) {
				this.resetFilters();
			}

			this.selectedSite = selectedSite;
			this.firstSiteLoad = false;
			this.clearPreviousData();
			this.getCollections();
			
		});

		this.eventNotificationService.getEventDispatcher$().subscribe((event) => {
			if (event.type === EventType.MOBILE_VIEW_SELECTED) {
				this.mobileViewSelected = <boolean>event.payload;
			}
		})
    }

    restoreFilters() {
		const filters = JSON.parse(sessionStorage.getItem(`${this.category}-filters`)) || null;

		if (filters) {
			this.searchText = filters.searchText;
			this.selectedCollectionClassList = filters.selectedClassList;
		}
	}

	resetFilters() {
		this.searchText = '';
		this.selectedCollectionClassList = [];
		sessionStorage.setItem(`${this.category}-filters`, JSON.stringify({ searchText: this.searchText, selectedClassList: this.selectedCollectionClassList }));
	}

	onFilterChangedHandler($event: any) {
		this.searchText = $event.searchText;
		this.selectedCollectionClassList = $event.selectedClassList;

		if ($event.action === 'apply') {
			this.filterCollections();
		}
		
		if ($event.action === 'clearFilter') {
			this.clearCollectionsFilters();
		}

		if ($event.action === 'clearSearch') {
			this.clearSearchInput();
		}
	}

	getCollections() {
		this.backendService.getDataByCategory(this.category, this.username, this.selectedSite, this.searchText, this.selectedCollectionClassList).subscribe((response: Collection[]) => {
			this.collections = response;
			this.noOfPinnedCollections = this.collections.filter(item => item.pinned).length;
			this.generateCollectionsFilters();
			this.callKpiPolling(response
				.flatMap((value) => value.selectedKpi)
				.map((value: any) => value.pointUUID))
		})
	}

	generateCollectionsFilters() {
		this.backendService.getCollectionsClassifications(this.selectedSite.siteId).subscribe((response: any[]) => {
			let collectionsClassification = {};

			response.map(item => {
				let collectionCategory = item.category.split("#")[1];
				if (!collectionsClassification[collectionCategory]) {
					collectionsClassification[collectionCategory] = [];
				}
				collectionsClassification[collectionCategory].push(item.entityClass.split("#")[1].replaceAll('_', ' '));
			});

			this.collectionFiltersOptions = Object.keys(collectionsClassification).map(category => {
				return {
					label: category.replaceAll('_', ' '),
					value: category,
					items: collectionsClassification[category].map((eqClass: string) => { return { 'label': eqClass.replaceAll('_', ' '), 'value': eqClass } })
				}
			});
		})
	}

	filterCollections() {
		this.backendService.getDataByCategory(this.category, this.username, this.selectedSite, this.searchText, this.selectedCollectionClassList)
			.subscribe((response: Collection[]) => {
				this.collections = response;
			});
	}

	clearFilters(searchText: string, equipmentClassList: string[]) {
		this.backendService.getDataByCategory(this.category, this.username, this.selectedSite, searchText, equipmentClassList)
			.subscribe((response: Collection[]) => {
				this.collections = response;
			});
	}

	clearSearchInput() {
		this.searchText = '';
		this.clearFilters(this.searchText, this.selectedCollectionClassList);
	}

	clearCollectionsFilters() {
		this.selectedCollectionClassList = [];
		this.clearFilters(this.searchText, this.selectedCollectionClassList);
	}

	clearPreviousData() {
        if (this.polledKPIPointsValuesSubscription) {
			this.polledKPIPointsValuesSubscription.unsubscribe();
		}
		this.closeRsocket();
        this.collections = [];
    }

    callKpiPolling(datapointIdListFilter: string[]) {
		////////
		// Create an instance of a rSocketClient
		this.rSocketClient = this.backendService.getRSocketClient();
		// Open the connection
		this.polledKPIPointsValuesSubscription = this.rSocketClient.connect().subscribe({
			onComplete: (socket: RSocket) => {
				let requestStream = 'request-stream-get-datapoint-list2';
				socket
					.requestStream({
						data: {
							'siteId': this.selectedSite.siteId,
							'datapointIdListFilter': datapointIdListFilter.filter(datapointId => datapointId !== null),
							'jwtToken': this.authService.getIdToken(),
							'interaction': 'Request'
						}, // null is a must if it does not include a message payload, else the Spring server side will not be matched.
						metadata: String.fromCharCode(requestStream.length) + requestStream
					})
					.subscribe({
						onComplete: () => console.log('complete'),
						onError: error => {
							console.log("Connection has been closed due to:: " + error);
						},
						onNext: payload => {
							this.mapCollectionKPI(payload.data);
						},
						onSubscribe: subscription => {
							subscription.request(1000000);
						},
					});

			},
			onError: error => {
				console.log("Connection has been refused due to:: " + error);
			},
			onSubscribe: cancel => {
				/* call cancel() to abort */
			}
		});

	}

    mapCollectionKPI(point: any) {
		const mappedPointsPerId = {};

		this.collections.forEach(collection => {
			const pointObjectReference = collection.selectedKpi;
			if (pointObjectReference) {
				mappedPointsPerId[pointObjectReference.pointUUID] = pointObjectReference;
			}
		})

		const existingPointObjectReference = mappedPointsPerId[point.id];
		if (existingPointObjectReference) {
			existingPointObjectReference.presentValue = point.lastRecordedValue;
			existingPointObjectReference.signalType = point.signalType;
			existingPointObjectReference.type = point.type;
			existingPointObjectReference.units = point.units;

			if (point.signalType === BINARY) {
				existingPointObjectReference.presentValue = point.lastRecordedValue ? true : false;
			}
		}
	}

	togglePin(event: MouseEvent, collection: Collection) {
		event.stopPropagation();
		const operation = collection.pinned ? Operation.Delete : Operation.Add
		this.backendService.entityAddRemovePin(this.category, this.username, this.selectedSite, operation, `urn:uuid:${collection.id}`).subscribe((response) => {
			collection.pinned = !collection.pinned;
			if (collection.pinned) {
				this.noOfPinnedCollections++;
			} else {
				this.noOfPinnedCollections--;
			}
		}, (err) => {
			console.log(err);
		})
	}

    ngOnDestroy() {
		if (this.polledKPIPointsValuesSubscription) {
			this.polledKPIPointsValuesSubscription.unsubscribe();
		}

		this.closeRsocket();
	}

    private closeRsocket() {
		if (this.rSocketClient) {
			this.rSocketClient.close();
		}
	}

}
